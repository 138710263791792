<template>
  <Container>
    <Header>
      Brews
      <template #right>
        <BadgeBtn @click="openBrew()">
          + Brew
        </BadgeBtn>
      </template>
    </Header>
    <BrewTable :brews="popBrews" />
  </Container>
</template>

<script>
import BrewTable from "../components/tabels/BrewTable.vue"
import comp from "../components/base"

import { mapActions } from "vuex"
import { computed } from "vue"
import { useStore } from "@/store/store"

export default {
  name: "Brew",
  components: {
    ...comp,
    BrewTable,
  },
  setup() {
    return {
      popBrews: computed(() => useStore().getters.popBrews),
    }
  },
  methods: {
    ...mapActions(["openBrew"]),
  },
}
</script>
